import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h2>{`Usage`}</h2>
    <p>{`Use checkboxes to allow users to select multiple items from a list of individual items, or to mark one individual item as selected.`}</p>
    <h3>{`States`}</h3>
    <p>{`Checkboxes have static dimensions and three different states: rest (default), focus, and disabled.`}</p>
    <img width="960" alt="An image showing checkboxes with three different states, rest, focus, and disabled." src="https://user-images.githubusercontent.com/586552/204882576-5b698ce7-c471-4f4e-9f33-c861842f5743.png" />
    <h3>{`Selected states`}</h3>
    <p>{`Checkboxes are capable of showing two forms of selection: checked (left) or indeterminate (right).`}</p>
    <img width="960" alt="An image showing checkboxes selected as checked and indeterminate" src="https://user-images.githubusercontent.com/586552/204883614-01743d25-1f7d-4261-b59d-8dd7ceaf29d3.png" />
    <Caption mdxType="Caption">The indeterminate state is colored in some browsers (e.g. Safari) and grey in others (e.g. Chrome).</Caption>
    <h3>{`Best practices`}</h3>
    <ul>
      <li parentName="ul">{`An individual checkbox should not have its own validation message or style. Instead, show a validation message on the `}<a parentName="li" {...{
          "href": "/components/checkbox-group"
        }}>{`checkbox group`}</a>{`. For more information, see the `}<a parentName="li" {...{
          "href": "../ui-patterns/forms/overview#validation"
        }}>{`Validation Message`}</a>{` section in the Forms documentation.`}</li>
      <li parentName="ul">{`An individual checkbox button cannot be marked as required. Instead, make a selection required using the `}<a parentName="li" {...{
          "href": "/components/checkbox-group"
        }}>{`checkbox group`}</a>{`. For more information, see the `}<a parentName="li" {...{
          "href": "../ui-patterns/forms/overview#required-field-indicator"
        }}>{`Required field indicator`}</a>{` in the Forms documentation.`}</li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="Checkbox" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../ui-patterns/forms/overview"
        }}>{`Forms`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/form-control"
        }}>{`Form control`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/checkbox-group"
        }}>{`Checkbox group`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/radio"
        }}>{`Radio`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/radio-group"
        }}>{`Radio group`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      